import React from "react";
import { PageLoader } from "./components/page-loader";

export const App = () => {
  return (
    <div className="page-layout">
      <PageLoader />
    </div>
  );
};
